import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ERROR_STR, REG_STATUS, REG_STATUS_LABEL, TOURNAMENT_CATEGORY, USER_ROLE, dialogButtonsProps, dialogProps, infoProps, snackbarProps } from '../../constant';
import { API_STFI } from '../../api/stfi';
import Info from '../../components/Info';
import Snackbar from '../../components/Snackbar';
import Backdrop from '../../components/Backdrop';
import Dialog from '../../components/Dialog';
import { getUserInfo } from '../../guards/auth-guard';
import TounamentSelectStates from './TounamentSelectStates';

function applyPagination(documents, page, rowsPerPage) {
  return documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}

const useCustomers = (data, page, rowsPerPage) => {
  return useMemo(
    () => {
      return applyPagination(data, page, rowsPerPage);
    },
    [data, page, rowsPerPage]
  );
};

const TournamentView = ({ setSelectedTournament, setNationalData }) => {

  const user = getUserInfo();

  const [loading, setLoading] = useState(true);

  const [backdrop, setBackdrop] = useState(false);

  const [dialog, setDialog] = useState(dialogProps.init());

  const [snackbar, setSnackbar] = useState(snackbarProps.init());
  snackbar.hide = () => {
    setSnackbar(snackbarProps.hide());
  };

  const [info, setInfo] = useState(infoProps.init());

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tableData = useCustomers(data, page, rowsPerPage);

  const [qualifiedStates, setQualifiedStates] = useState(null);

  const handlePageChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event) => {
      setRowsPerPage(event.target.value);
    },
    []
  );

  const fetchData = async () => {
    try {

      var response = (user.role === USER_ROLE.ADMIN) ? await API_STFI.TOURNAMENT_VIEW() : await API_STFI.TOURNAMENT_STATE_VIEW();

      setData(response.data);

      // Hide loader
      setLoading(false);

      setInfo(infoProps.close());

    } catch (error) {

      // Hide loader
      setLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Server responded with an error:', error.response.status, error.response);
        setInfo(infoProps.error(error.response.statusText));
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received from the server');
        setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
        setInfo(infoProps.error(ERROR_STR.STD));
      }
    }
  }

  const onDelete = async ({ id, name }) => {

    var buttons = [];
    buttons.push(dialogButtonsProps.init("Delete", async () => {

      setDialog(dialogProps.close());
      setBackdrop(true);

      try {

        // Authenticate the google token
        const response = await API_STFI.TOURNAMENT_DELETE(id);
        console.log(response);

        // Hide loader
        setBackdrop(false);

        fetchData();

      } catch (error) {

        // Hide loader
        setBackdrop(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Server responded with an error:', error.response.status, error.response);
          setSnackbar(snackbarProps.error(error.response.statusText));
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received from the server');
          setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error setting up the request:', error.message);
          setSnackbar(snackbarProps.error(ERROR_STR.STD));
        }
      }
    }));
    buttons.push(dialogButtonsProps.init("Close", () => {
      setDialog(dialogProps.close());
    }));

    setDialog(dialogProps.info("Delete", `Do you want to delete ${name} ?`, buttons));
  };

  const updateTournament = (data) => {
    setSelectedTournament(data);
  };

  useEffect(() => {

    fetchData();

  }, []);

  const getRegStatus = (s) => {
    if (s === REG_STATUS.YET_TO_OPEN) return REG_STATUS_LABEL.YET_TO_OPEN;
    else if (s === REG_STATUS.OPENED) return REG_STATUS_LABEL.OPENED;
    else if (s === REG_STATUS.CLOSED) return REG_STATUS_LABEL.CLOSED;
    else if (s === REG_STATUS.REGISTERED) return REG_STATUS_LABEL.REGISTERED;
    else return "-";
  };

  const listStates = async (data) => {

    try {
      setLoading(true);
      var response = await API_STFI.STATES();

      var states = {};
      response.data.forEach((s) => {
        states[s.stateCode] = s.stateName;
      })

      var obj = [];
      data.events.forEach(v => {

        v.qualifiedMaleStateCodes.forEach((p) => {
          obj[p] = { stateCode: p, enrolled: false };
        })

        v.qualifiedFemaleStateCodes.forEach((p) => {
          obj[p] = { stateCode: p, enrolled: false };
        })

      });

      data.registeredStates.forEach(v => {
        obj[v].enrolled = true;
      });

      var arr = [];
      for (var key in obj) {
        obj[key].stateName = states[obj[key].stateCode];
        arr.push(obj[key]);
      }

      console.log(arr);

      setSelectedTournament({...data, states:arr});

      // Hide loader
      setLoading(false);

    } catch (error) {

      // Hide loader
      setLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Server responded with an error:', error.response.status, error.response);
        setInfo(infoProps.error(error.response.statusText));
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received from the server');
        setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
        setInfo(infoProps.error(ERROR_STR.STD));
      }
    }
  };

  return (
    <>
      {
        (loading) ?
          <Box sx={{ width: '100%', height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
          :
          (info.open)?
          <Info {...info} />
          :
          (data.length) ?
            <Card sx={{ overflowX: "auto" }}>
              <Box sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Name
                      </TableCell>
                      <TableCell>
                        Venue
                      </TableCell>
                      <TableCell>
                        Category
                      </TableCell>
                      <TableCell>
                        Status
                      </TableCell>
                      <TableCell>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((data, i) => {

                      return (
                        <TableRow
                          hover
                          key={i}
                        >
                          <TableCell>
                            {data.name}
                          </TableCell>
                          <TableCell>
                            {data.venue}
                          </TableCell>
                          <TableCell>
                            {(data.category === TOURNAMENT_CATEGORY.NATIONAL) ? "National" : (data.category === TOURNAMENT_CATEGORY.INTERNATIONAL) ? "International" : ""}
                          </TableCell>
                          <TableCell>
                            {getRegStatus(data.status)}
                          </TableCell>
                          <TableCell>
                            {
                              (user.role === USER_ROLE.STATE_ADMIN) ?
                                <>
                                  {
                                    (data.status === REG_STATUS.OPENED) ?
                                      <Button
                                        onClick={() => { updateTournament(data) }}
                                      >
                                        Enroll
                                      </Button>
                                      :
                                      (data.status === REG_STATUS.REGISTERED) ?
                                        <Button
                                          onClick={() => { setNationalData(data) }}
                                        >
                                          View
                                        </Button>
                                        :
                                        <>-</>
                                  }
                                </>
                                :
                                (data.category === TOURNAMENT_CATEGORY.NATIONAL) ?
                                  <>
                                    {
                                      (data.status === REG_STATUS.YET_TO_OPEN) ?
                                        <Button
                                          onClick={() => { updateTournament(data) }}
                                        >
                                          Edit
                                        </Button>
                                        :
                                        (data.status === REG_STATUS.OPENED || data.status === REG_STATUS.CLOSED) ?
                                          <Button
                                            onClick={() => { listStates(data) }}
                                          >
                                            View
                                          </Button>
                                          :
                                          <>-</>
                                    }
                                  </>
                                  :
                                  <></>
                            }
                            {
                              (user.role === USER_ROLE.ADMIN) ?
                                <Button onClick={() => onDelete(data)}>Delete</Button>
                                :
                                <></>
                            }

                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
              <TablePagination
                component="div"
                count={data.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Card>
            :
            <Typography>No Tournaments</Typography>
      }
      <Snackbar {...snackbar} />
      <Backdrop open={backdrop} />
      <Dialog {...dialog} />
    </>
  )
}

export default TournamentView