import { CssBaseline, ThemeProvider } from '@mui/material';
import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import PageNotFound from './pages/PageNotFound';
import { createTheme } from './theme';
import Login from './pages/Login';
import NewsFeed from './pages/NewsFeed';
import ReLogin from './pages/ReLogin';
import Users from './pages/Users';
import States from './pages/States';
import RegistrationsView from './pages/RegistrationsView';
import RegistrationsForm from './pages/RegistrationsForm';
import Dashboard from './pages/Dashboard';
import Gallery from './pages/Gallery';
import Profile from './pages/Profile';
import ReactGA from "react-ga4";
import { useEffect } from 'react';
import { GOOGLE_MEASUREMENT_ID } from './constant';
import Tournament from './pages/Tournament';
import RegistrationSearch from './pages/RegistrationSearch';

function App() {

  // ReactGAImplementation.
  ReactGA.initialize(GOOGLE_MEASUREMENT_ID);

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const theme = createTheme();
  const router = createBrowserRouter([
    {
      path: "/*",
      Component: PageNotFound,
    },
    {
      path: "/login",
      Component: Login,
    },
    {
      path: "/",
      Component: Login,
    },
    {
      path: "/newsfeed",
      Component: NewsFeed,
    },
    {
      path: "/gallery",
      Component: Gallery,
    },
    {
      path: "/profile",
      Component: Profile,
    },
    {
      path: "/relogin",
      Component: ReLogin,
    },
    {
      path: "/users",
      Component: Users,
    },
    {
      path: "/states",
      Component: States,
    },
    {
      path: "/registration/view",
      Component: RegistrationsView,
    },
    {
      path: "/registration/form",
      Component: RegistrationsForm,
    },
    {
      path: "/registration/search",
      Component: RegistrationSearch,
    },
    {
      path: "/dashboard",
      Component: Dashboard,
    },
    {
      path: "/tournament",
      Component: Tournament,
    }
  ]);

  return (
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
