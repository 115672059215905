import { Avatar, Box, Button, Card, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, FormGroup, Unstable_Grid2 as Grid, Radio, RadioGroup, Stack, Step, StepContent, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react'
import { EVENTS_LABEL, RULE_PATTERN_VALUE } from '../../constant';
import { API_STFI } from '../../api/stfi';
import { useNavigate } from 'react-router-dom';
import { getUserInfo } from '../../guards/auth-guard';

function SelectNationalPlayers({ role, gender, players, coaches, managers, dispatch, setBackdrop, totalMembers }) {

    const navigate = useNavigate();
    const user = getUserInfo();

    const title = `${(role === "R") ? "Add Referee" : (gender === "M") ? "Add Male Players" : "Add Female Players"}`;

    const SelectEvents = () => {

        const selectEvent = useCallback(
            (event) => {

                const { name, checked } = event.target;

                dispatch({ type: "SELECT_EVENT", role: "P", gender: gender, event: name, add: checked });
            },
            [dispatch]
        );

        return (
            <FormControl sx={{ mt: 1 }}>
                <FormGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={() => { }}
                >
                    {
                        players.qualifiedEvents.map((key, i) => {
                            return (
                                <FormControlLabel key={i} name={key} control={<Checkbox onChange={selectEvent} checked={players.selectedEvents.includes(key)} />} label={EVENTS_LABEL[key]} />
                            )
                        })
                    }
                </FormGroup>
            </FormControl>
        );
    };

    const AddMembers = ({ role }) => {

        const [registrationId, setRegistrationId] = useState("");
        const [error, setError] = useState("");
        const regRole = (role === "C" || role === "M")?"O":role;

        const handleChange = useCallback(
            (event) => {

                const { value } = event.target;

                setError("");
                setRegistrationId(value);
            },
            []
        );

        function getRoleFromRegId(id) {
            for (let i = id.length - 1; i >= 0; i--) {
                const char = id[i];
                if (isNaN(char)) {
                    return char
                }
            }
            return "";
        }

        const getDetails = useCallback(async (registrationId) => {

            const isValid = new RegExp(RULE_PATTERN_VALUE.REG_ID).test(registrationId);
            if (!isValid) {
                // Display error message (e.g., using a state variable)
                setError("Invalid Registration ID format");
                return;
            }

            if(getRoleFromRegId(registrationId) !== regRole) {
                setError("Invalid Role");
                return;
            }

            try {

                // setBackdrop(true);

                setError("Please wait loading ...!")

                const response = await API_STFI.GET_SA_REGISTRATION_ID(registrationId);

                setError("")
                // setBackdrop(false);

                if (response.status === 200) {

                    if(totalMembers.includes(response.data.emailId)) {
                        setError("This registration ID is already been added");
                        return;
                    }

                    response.data.regId = (response.data.playerRegistrationId || response.data.officialRegistrationId || response.data.refereeRegistrationId);

                    if (role === "P") {

                        if (response.data.gender === gender) {

                            dispatch({ type: "ADD_MEMBER", role: role, gender: gender, member: response.data });
                        }
                        else {
                            setError(`Enter a ${(gender === "M") ? "Male" : "Female"} registration ID`);
                        }
                    }
                    else if (role === "R") {
                        dispatch({ type: "ADD_MEMBER", role: role, member: response.data });
                    }
                    else {
                        dispatch({ type: "ADD_MEMBER", role: role, gender: gender, member: response.data });
                    }
                }
                else {
                    setError("Enter a valid registration ID");
                }

            } catch (error) {

                if (error.response) {

                    if (error.response.status === 401) {
                        navigate("/");
                    }

                    setError("Enter a valid registration ID");
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Server responded with an error:', error.response.status, error.response);


                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received from the server');
                    setError("Enter a valid registration ID");
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error setting up the request:', error.message);
                    setError(error.message);
                }
            }
        }, []);

        return (
            <Grid md={12} >
                <Grid
                    md={6}
                    sx={{ alignItems: "center" }}
                    mt={2}
                >
                    <TextField
                        label={"Registration ID"}
                        name={"registrationId"}
                        required={true}
                        onChange={handleChange}
                        value={registrationId}
                        helperText={`Example - ${user.stateCode}${regRole}001`}
                        inputProps={{ pattern: RULE_PATTERN_VALUE.REG_ID }}
                    />
                    <Button sx={{ marginLeft: 3, mt: 0.5 }} onClick={() => { getDetails(registrationId) }} variant='outlined'>ADD</Button>
                    <Typography sx={{ color: "red", p: 1 }} >{error}</Typography>
                </Grid>
            </Grid>
        );
    };

    const SelectPlayers = () => {

        const selectPlayer = useCallback(
            (event) => {

                const { name, checked } = event.target;
                const regId = event.target.getAttribute("regid");

                dispatch({ type: (checked) ? "SELECT_MEMBER" : "UNSELECT_MEMBER", role: "P", gender: gender, event: name, regId: regId })
            },
            [dispatch]
        );

        return (
            <Grid
                md={12}
            >
                <TableContainer >
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Box>Name</Box>
                                </TableCell>
                                <TableCell>
                                    <Box>REG ID</Box>
                                </TableCell>
                                {
                                    players.selectedEvents.map((event, i) => {
                                        return (
                                            <TableCell key={i} >
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                                        <Box>{EVENTS_LABEL[event]}</Box>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        )
                                    })
                                }
                                <TableCell>
                                    <Box>Action</Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                players.addedMembers.map((data, i) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={i}
                                        >
                                            <TableCell>
                                                <Stack
                                                    alignItems="center"
                                                    direction="row"
                                                    spacing={2}
                                                >
                                                    <Avatar sx={{ background: "#e2e2e2" }} src={(data.passportSizePhoto)}>
                                                    </Avatar>
                                                    <Typography variant="subtitle2">
                                                        {data.name}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                {data.regId}
                                            </TableCell>
                                            {
                                                players.selectedEvents.map((event, j) => {
                                                    return (
                                                        <TableCell key={j} >
                                                            <FormControlLabel name={event}
                                                                control={<Checkbox checked={players.selectedMembers[event].includes(data.regId)} onChange={selectPlayer} inputProps={{ 'regid': data.regId }} />}
                                                            />
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                            <TableCell>
                                                <Button variant={"outlined"} onClick={() => {
                                                    dispatch({ type: "REMOVE_MEMBER", role: "P", gender: gender, regId: data.regId, emailId: data.emailId });
                                                }}>Remove</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        );
    };

    const ViewMembers = ({ member, role }) => {

        return (
            <TableContainer >
                <Table  >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Box>{title}</Box>
                            </TableCell>
                            <TableCell>
                                <Box>REG ID</Box>
                            </TableCell>
                            <TableCell>
                                <Box>Action</Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            member.map((data, i) => {
                                return (
                                    <TableRow
                                        hover
                                        key={i}
                                    >
                                        <TableCell>
                                            <Stack
                                                alignItems="center"
                                                direction="row"
                                                spacing={2}
                                            >
                                                <Avatar sx={{ background: "#e2e2e2" }} src={(data.passportSizePhoto)}>
                                                </Avatar>
                                                <Typography variant="subtitle2">
                                                    {data.name}
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {data.regId}
                                        </TableCell>
                                        <TableCell>
                                            <Button variant={"outlined"} onClick={() => {
                                                dispatch({ type: "REMOVE_MEMBER", role: role, gender: gender, regId: data.regId , emailId: data.emailId });
                                            }}>Remove</Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    const togglePlaying = (event) => {
        dispatch({ type: "PLAYING", role: "P", gender: gender, playing: (event.target.value === "Y") ? true : false });
    };

    const checkPlayersSelected = () => {
        var hasPlayers = 0;
        players.selectedEvents.map((e) => {
            if (players.selectedMembers[e].length) hasPlayers++;
        });
        return players.selectedEvents.length === hasPlayers;
    };

    const steps = [
        {
            label: 'Confirm Participation',
            description: ``,
        },
        {
            label: 'Choose Events',
            description: `You can choose one or more events`,
        },
        {
            label: 'Add and Select Players',
            description:
                'Add all eligible players using their registration ID and select based the players based on the events',
        },
        {
            label: 'Add Coaches',
            description: `Add all coaches using their registration ID`,
        },
        {
            label: 'Add Managers',
            description: `Add all managers using their registration ID`,
        },
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Card>
            <CardHeader
                subheader={""}
                title={`${title}`}
            />
            <CardContent sx={{ pt: 0 }}>
                <Box sx={{ m: -1.5, p: 2 }}>
                    {
                        (role === "R") ?
                            <>
                                <AddMembers role={"R"} />
                                {
                                    (players.addedMembers.length) ?
                                        <ViewMembers title={"Referee"} role={"R"} member={players.addedMembers} />
                                        :
                                        <></>
                                }
                            </>
                            :
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.label} active={(players.playing) ? true : undefined} >
                                        <StepLabel
                                            optional={
                                                index === steps.length - 1 ? (
                                                    <Typography variant="caption">Last step</Typography>
                                                ) : null
                                            }
                                        >
                                            {step.label}
                                        </StepLabel>
                                        <StepContent>
                                            <Typography>{step.description}</Typography>
                                            {
                                                (index === 0) ?
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        name="radio-buttons-group"
                                                        onChange={togglePlaying}
                                                    >
                                                        <FormControlLabel value="Y" control={<Radio checked={(players.playing)?true:false} />} label="Yes" />
                                                        <FormControlLabel value="N" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                    :
                                                    (index === 1) ?
                                                        <SelectEvents />
                                                        :
                                                        (index === 2) ?
                                                            <>
                                                                <AddMembers role={"P"} />
                                                                {
                                                                    (players.addedMembers.length) ?
                                                                        <SelectPlayers />
                                                                        :
                                                                        <></>
                                                                }

                                                            </>
                                                            :
                                                            (index === 3) ?
                                                                <>
                                                                    <AddMembers role={"C"} />
                                                                    {
                                                                        (coaches.length) ?
                                                                            <ViewMembers title={"Coach"} role={"C"} member={coaches} />
                                                                            :
                                                                            <></>
                                                                    }

                                                                </>
                                                                :
                                                                (index === 4) ?
                                                                    <>
                                                                        <AddMembers role={"M"} />
                                                                        {
                                                                            (managers.length) ?
                                                                                <ViewMembers title={"Manager"} role={"M"} member={managers} />
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <></>
                                            }
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                    }

                </Box>
            </CardContent>
        </Card>
    )
}

export default SelectNationalPlayers