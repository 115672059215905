import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Card, Button, CardContent, CardHeader, Unstable_Grid2 as Grid, TextField } from '@mui/material';
import { HELPER_TEXT, REG_FORM_KEY, REG_FORM_LABEL, RULE_PATTERN_VALUE } from '../../constant';

export const RegistrationSearchForm = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  let updatedSearchParams = new URLSearchParams(searchParams.toString());

  const [values, setValues] = useState({});

  const [errors] = useState({});

  const handleChange = useCallback(
    (event) => {

      const { name, value } = event.target;

      setValues((prevState) => ({
        ...prevState,
        [name]: value
      }));
    },
    [setValues]
  );

  const onSubmit = () => {
    updatedSearchParams.set("url", btoa(`${window.location.origin}/stfi/stateAdmin/registration/getRegistrationDetails?email=${values[REG_FORM_KEY.EMAIL]}`));
    setSearchParams(updatedSearchParams.toString());
  };

  return (
    <form
    >
      <Card sx={{ height: "100%" }}>
        <CardHeader
          subheader={"Enter the email Id to check the registration status"}
          title={"Search"}
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
              p={1}
            >
              <Grid
                xs={10}
              >
                <TextField
                  fullWidth
                  label={REG_FORM_LABEL.EMAIL}
                  name={REG_FORM_KEY.EMAIL}
                  required
                  onChange={handleChange}
                  value={values[REG_FORM_KEY.EMAIL]}
                  error={errors[REG_FORM_KEY.EMAIL]}
                  helperText={errors[REG_FORM_KEY.EMAIL] ? HELPER_TEXT.GMAIL : ''}
                  inputProps={{ pattern: RULE_PATTERN_VALUE.GMAIL }}
                />
              </Grid>
              <Grid xs={2} sx={{ alignContent: "center" }} onClick = {onSubmit} >
                <Button type='submit' variant='contained'>Search</Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};
