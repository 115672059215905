import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { ERROR_STR, infoProps } from '../../constant';
import { API_STFI } from '../../api/stfi';
import Info from '../../components/Info';
import Backdrop from '../../components/Backdrop';
import NationalView from './NationalView';

function TournamentViewMembers({ selectedTournament }) {

    const [stateCode, setStateCode] = useState("val");
    const [backdrop, setBackdrop] = useState(false);
    const [info, setInfo] = useState(infoProps.init());
    const [data, setData] = useState([]);
    const [values, setValues] = useState({});

    const stateOnChange = (event) => {
        setStateCode(event.target.value);
    };

    const fetchData = useCallback(async () => {

        if (!selectedTournament || !stateCode) return;

        try {

            setBackdrop(true);
            // Authenticate the google token
            var response;

            response = await API_STFI.TOURNAMENT_STATE_GET_NATIONAL_DETAILS(selectedTournament.id, stateCode);

            setData(response.data);

            // Hide loader
            setBackdrop(false);

            setInfo(infoProps.close());

        } catch (error) {

            setData([]);
            // Hide loader
            setBackdrop(false);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Server responded with an error:', error.response.status, error.response);
                setInfo(infoProps.error(error.response.statusText));
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received from the server');
                setInfo(infoProps.error(ERROR_STR.NO_RESPONSE));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', error.message);
                setInfo(infoProps.error(ERROR_STR.STD));
            }
        }
    }, [stateCode]);

    useEffect(() => {
        //if(selectedTournament?.id && stateCode && stateCode !== "val") fetchData();
    }, []);

    const onChange = useCallback(
        (event) => {

            const { name, value, checked } = event.target;


            setStateCode(value);
        },
        []
    );

    return (
        <>
            {
                (backdrop) ?
                    <Backdrop open={backdrop} />
                    :
                    (info.open) ?
                        <Info {...info} />
                        :
                        <>
                            <Typography variant='h6'>{selectedTournament.name}</Typography>
                            <TextField
                                label="State"
                                name="state"
                                size="small"
                                select
                                SelectProps={{ native: true }}
                                onChange={onChange}
                                value={stateCode}
                            >
                                <option key={"val"} value={"val"} >Select a state</option>
                                {
                                    (selectedTournament.states) ?
                                        selectedTournament.states.map((option) => (
                                            <option
                                                key={option.stateCode}
                                                value={option.stateCode}
                                                disabled = {!option.enrolled}
                                                title = {(option.enrolled)?"":"Not Enrolled state"}
                                            >
                                                {option.stateName}
                                            </option>
                                        ))
                                        :
                                        <></>
                                }
                            </TextField>
                            {
                                (stateCode && stateCode !== "val") ?
                                    <NationalView nationalData={selectedTournament} stateCode={stateCode} />
                                    :
                                    <></>
                            }
                        </>
            }
        </>
    )
}

export default TournamentViewMembers