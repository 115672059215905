import React, { useState } from 'react'
import { Layout } from '../layouts/dashboard/layout'
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { getUserInfo } from '../guards/auth-guard';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { USER_ROLE } from '../constant';
import TournamentView from '../sections/tournament/TournamentView';
import TournamentForm, { TOURNAMENT_FORM_KEY } from '../sections/tournament/TournamentForm';
import TournamentFormView from '../sections/tournament/TournamentFormView';
import SelectNationalsMembers from '../sections/tournament/SelectNationalsMembers';
import NationalView from '../sections/tournament/NationalView';
import TournamentViewMembers from '../sections/tournament/TournamentViewMembers';

const Tournament = () => {

  const user = getUserInfo();

  const [searchParams] = useSearchParams();
  const action = searchParams.get("action") || "view";

  const [selectedTournament, setSelectedTournament] = useState(null);
  const [nationalData, setNationalData] = useState(null);

  const navigate = useNavigate();

  const ActionButton = () => {
    if (action !== "view" || selectedTournament || nationalData) {

      return (
        <Button
          sx={{ mt: 3 }}
          variant="outlined"
          onClick={() => { setSelectedTournament(null); setNationalData(null); navigate("/tournament") }}
        >
          Back
        </Button>
      );
    }
    else if (user.role === USER_ROLE.ADMIN) {

      return (
        <>
          <Button
            component={Link}
            to={"/tournament?action=add&type=N"}
            sx={{ mt: 3 }}
            variant="outlined"
          >
            Create National
          </Button>
          <Button
            component={Link}
            to={"/tournament?action=add&type=I"}
            sx={{ mt: 3 }}
            variant="outlined"
          >
            Create International
          </Button>
        </>
      );
    }
    else return <></>;
  }

  return (
    <Layout user={user} >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1} sx={{ width: "100%" }} >
                <Typography variant="h5">
                  Tournament
                </Typography>
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                  justifyContent={"right"}
                  sx={{ width: "100%" }}
                >
                  <ActionButton />
                </Stack>
              </Stack>
            </Stack>
            {
              nationalData ?
                <NationalView nationalData={nationalData} setNationalData={setNationalData} />
                :
                selectedTournament ?
                  (user.role === USER_ROLE.STATE_ADMIN) ?
                    <SelectNationalsMembers selectedTournament={selectedTournament} />
                    :
                    (user.role === USER_ROLE.ADMIN) ?
                      <TournamentViewMembers selectedTournament={selectedTournament} />
                      :
                      (selectedTournament[TOURNAMENT_FORM_KEY.CATEGORY] === "I") ?
                        <TournamentFormView data={selectedTournament} />
                        :
                        <TournamentForm selectedTournament={selectedTournament} setSelectedTournament={setSelectedTournament} />
                  :
                  (action === "view") ?
                    <TournamentView setSelectedTournament={setSelectedTournament} setNationalData={setNationalData} />
                    :
                    <TournamentForm selectedTournament={selectedTournament} setSelectedTournament={setSelectedTournament} />
            }
          </Stack>
        </Container>
      </Box>
    </Layout>
  )
}

export default Tournament