import { Box, Card, CardContent, CardHeader, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { TOURNAMENT_FORM_KEY } from './TournamentForm'
import { EVENTS_LABEL, TOURNAMENT_VIEW_STYLE } from '../../constant'
import FilePreviewBtn from '../../components/FilePreviewBtn';

const QualifiedEvents = ({ header, events }) => {
    return (
        <Grid
            xs={12}
            sx={TOURNAMENT_VIEW_STYLE.wrapper}
        >
            <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                {header}
            </Typography>
            <Stack spacing={1}>
                <ul style={{ marginTop: 10, display: "flex", flexDirection: "column", gap: "10px", fontSize: "1.1em" }}>
                    {
                        events.map((k, i) => {
                            return (
                                <li key={i}>{EVENTS_LABEL[k]}</li>
                            )
                        })
                    }
                </ul>
            </Stack>
        </Grid>
    );
};

function NationalsBasicDetails({ data }) {

    const getQualifiedEvents = (events, gender) => {
        if(!events) return [];
        else return events.filter((event) => (event[gender === "M" ? "qualifiedMale" : "qualifiedFemale"]) ? true : false).map((v) => v.
        event
        );
    };

    const qualifiedMale = getQualifiedEvents(data[TOURNAMENT_FORM_KEY.EVENTS], "M");

    const qualifiedFemale = getQualifiedEvents(data[TOURNAMENT_FORM_KEY.EVENTS], "F");

    return (
        <Card sx={{ height: "100%" }}>
            <CardHeader
                subheader={""}
                title={"Basic Details"}
            />
            <CardContent sx={{ pt: 0 }}>
                <Box sx={{ m: -1.5 }}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            md={6}
                        >
                            {
                                (data[TOURNAMENT_FORM_KEY.NAME]) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Name
                                        </Typography>
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                            {data[TOURNAMENT_FORM_KEY.NAME]}
                                        </Typography>
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                (data[TOURNAMENT_FORM_KEY.VENUE]) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Venue
                                        </Typography>
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                            {data[TOURNAMENT_FORM_KEY.VENUE]}
                                        </Typography>
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                (data[TOURNAMENT_FORM_KEY.START_DATE]) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            State Date
                                        </Typography>
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                            {data[TOURNAMENT_FORM_KEY.START_DATE]}
                                        </Typography>
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                (data[TOURNAMENT_FORM_KEY.END_DATE]) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            End Date
                                        </Typography>
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                            {data[TOURNAMENT_FORM_KEY.END_DATE]}
                                        </Typography>
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                (data[TOURNAMENT_FORM_KEY.REG_STARTING_DATE]) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Registration Starting Date
                                        </Typography>
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                            {data[TOURNAMENT_FORM_KEY.REG_STARTING_DATE]}
                                        </Typography>
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                (data[TOURNAMENT_FORM_KEY.REG_CLOSING_DATE]) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Registration Closing Date
                                        </Typography>
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                            {data[TOURNAMENT_FORM_KEY.REG_CLOSING_DATE]}
                                        </Typography>
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                (data[TOURNAMENT_FORM_KEY.AGE_LIMIT]) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.label}>
                                            Age limit
                                        </Typography>
                                        <Typography sx={TOURNAMENT_VIEW_STYLE.value} >
                                            {data[TOURNAMENT_FORM_KEY.AGE_LIMIT]}
                                        </Typography>
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                (data[TOURNAMENT_FORM_KEY.OFFICIAL_DOC]) ?
                                    <Grid
                                        xs={12}
                                        sx={TOURNAMENT_VIEW_STYLE.wrapper}
                                    >
                                        <FilePreviewBtn buttonLabel={"Offical Document"} url={data[TOURNAMENT_FORM_KEY.OFFICIAL_DOC]} />
                                    </Grid>
                                    :
                                    <></>
                            }
                        </Grid>
                        <Grid
                            md={6}
                        >
                            {
                                (qualifiedMale.length) ?
                                    <QualifiedEvents header={"Qualified Male Events"} events={qualifiedMale} />
                                    :
                                    <></>
                            }
                            {
                                (qualifiedFemale.length) ?
                                    <QualifiedEvents header={"Qualified Female Events"} events={qualifiedFemale} />
                                    :
                                    <></>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    )
}

export default NationalsBasicDetails