import { Box, Card, CardContent, CardHeader, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TOURNAMENT_FORM_KEY } from './TournamentForm'
import { ACCEPT_FILE_TYPE, HELPER_TEXT, RULE_PATTERN_VALUE } from '../../constant'

function TournamentBasicFields({ tournament, handleChange, selectedTournament, resetAgeLimit, openTournament, setOpenTournament}) {

  const openTournamentOnChange = (event) => {
    if (event.target.value === "Y") resetAgeLimit();
    setOpenTournament(event.target.value);
  };

  useEffect(() => {
    if (tournament[TOURNAMENT_FORM_KEY.AGE_LIMIT]) setOpenTournament("N");
  }, [tournament, setOpenTournament]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        subheader={``}
        title={`Create ${(tournament[TOURNAMENT_FORM_KEY.CATEGORY] === "N") ? "National" : "International"}`}
      />
      <CardContent sx={{ pt: 0 }}>
        <Box sx={{ m: -1.5 }}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              xs={12}
              item
            >
              <TextField
                fullWidth
                label={"Name"}
                name={TOURNAMENT_FORM_KEY.NAME}
                required
                onChange={handleChange}
                value={tournament[TOURNAMENT_FORM_KEY.NAME]}
                helperText={HELPER_TEXT.MAX_256}
                inputProps={{ pattern: RULE_PATTERN_VALUE.MAX_256 }}
              />
            </Grid>
            <Grid
              xs={12}
              item
            >
              <TextField
                fullWidth
                label={"Venue"}
                name={TOURNAMENT_FORM_KEY.VENUE}
                required
                onChange={handleChange}
                value={tournament[TOURNAMENT_FORM_KEY.VENUE]}
                helperText={HELPER_TEXT.MAX_256}
                inputProps={{ pattern: RULE_PATTERN_VALUE.MAX_256 }}
              />
            </Grid>
            <Grid
              xs={12}
              item
            >
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label={"State Date"}
                    name={TOURNAMENT_FORM_KEY.START_DATE}
                    value={(tournament[TOURNAMENT_FORM_KEY.START_DATE]) ? dayjs(tournament[TOURNAMENT_FORM_KEY.START_DATE]): null}
                    required={true}
                    format="DD-MM-YYYY"
                    sx={{ width: "100%" }}
                    onChange={(v) => { handleDateChange(TOURNAMENT_FORM_KEY.START_DATE, dayjs(v).format('DD-MM-YYYY'))}}
                  />
                </DemoContainer>
              </LocalizationProvider> */}
              <TextField
                fullWidth
                label={"State Date"}
                name={TOURNAMENT_FORM_KEY.START_DATE}
                value={tournament[TOURNAMENT_FORM_KEY.START_DATE]}
                helperText={HELPER_TEXT.DATE}
                inputProps={{ pattern: RULE_PATTERN_VALUE.DD_MM_YYYY }}
                required={true}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              xs={12}
              item
            >
              <TextField
                fullWidth
                label={"End Date"}
                name={TOURNAMENT_FORM_KEY.END_DATE}
                value={tournament[TOURNAMENT_FORM_KEY.END_DATE]}
                helperText={HELPER_TEXT.DATE}
                inputProps={{ pattern: RULE_PATTERN_VALUE.DD_MM_YYYY }}
                required={true}
                onChange={handleChange}
              />
            </Grid>
            {
              (tournament[TOURNAMENT_FORM_KEY.CATEGORY] === "N") ?
                <>
                  <Grid
                    xs={12}
                    item
                  >
                    <TextField
                      fullWidth
                      label={"Registration Starting Date"}
                      name={TOURNAMENT_FORM_KEY.REG_STARTING_DATE}
                      value={tournament[TOURNAMENT_FORM_KEY.REG_STARTING_DATE]}
                      helperText={HELPER_TEXT.DATE}
                      inputProps={{ pattern: RULE_PATTERN_VALUE.DD_MM_YYYY }}
                      required={true}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    item
                  >
                    <TextField
                      fullWidth
                      label={"Registration Closing Date"}
                      name={TOURNAMENT_FORM_KEY.REG_CLOSING_DATE}
                      value={tournament[TOURNAMENT_FORM_KEY.REG_CLOSING_DATE]}
                      helperText={HELPER_TEXT.DATE}
                      inputProps={{ pattern: RULE_PATTERN_VALUE.DD_MM_YYYY }}
                      required={true}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    item
                  >
                    <FormControl sx={{ m: 1 }}>
                      <FormLabel id="demo-radio-buttons-group-label">Open Tournament</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={openTournament}
                        name="radio-buttons-group"
                        onChange={openTournamentOnChange}
                      >
                        <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                        <FormControlLabel value="N" control={<Radio />} label="No" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {
                    (openTournament === "N") ?
                      <Grid
                        xs={12}
                        item
                      >
                        <TextField
                          fullWidth
                          label={"Age Limit"}
                          name={TOURNAMENT_FORM_KEY.AGE_LIMIT}
                          helperText={HELPER_TEXT.DATE2}
                          value={tournament[TOURNAMENT_FORM_KEY.AGE_LIMIT]}
                          inputProps={{ pattern: RULE_PATTERN_VALUE.MM_YYYY }}
                          required={true}
                          onChange={handleChange}
                        />
                      </Grid>
                      :
                      <></>
                  }
                </>
                :
                <></>
            }

            <Grid
              xs={12}
              item
            >
              <TextField
                fullWidth
                type="file"
                label={"Official Document"}
                name={TOURNAMENT_FORM_KEY.OFFICIAL_DOC}
                helperText={HELPER_TEXT.ONLY_PDF}
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: ACCEPT_FILE_TYPE.PDF }}
                required={(selectedTournament) ? false : true}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}

export default TournamentBasicFields