import React, { useCallback, useEffect, useReducer, useState } from 'react'
import NationalsBasicDetails from './NationalsBasicDetails';
import { TOURNAMENT_FORM_KEY } from './TournamentForm';
import SelectNationalPlayers from './SelectNationalPlayers';
import { Box, Button } from '@mui/material';
import Backdrop from '../../components/Backdrop';
import { dialogButtonsProps, dialogProps, ERROR_STR, snackbarProps } from '../../constant';
import { API_STFI } from '../../api/stfi';
import { useNavigate } from 'react-router-dom';
import Snackbar from '../../components/Snackbar';
import Dialog from '../../components/Dialog';

function SelectNationalsMembers({ selectedTournament }) {

    const [backdrop, setBackdrop] = useState(false);
    const [snackbar, setSnackbar] = useState(snackbarProps.init());
    snackbar.hide = () => {
        setSnackbar(snackbarProps.hide());
    };
    const [dialog, setDialog] = useState(dialogProps.init());
    const navigate = useNavigate();

    const initialeState = {
        P: {
            M: {
                qualifiedEvents: [],
                selectedEvents: [],
                addedMembers: [],
                selectedMembers: {
                    TE: [],
                    RE: [],
                    DE: [],
                    QE: [],
                },
                playing: null,
            },
            F: {
                qualifiedEvents: [],
                selectedEvents: [],
                addedMembers: [],
                selectedMembers: {
                    TE: [],
                    RE: [],
                    DE: [],
                    QE: [],
                },
                playing: null,
            }
        },
        C: {
            M: {
                addedMembers: [],
            },
            F: {
                addedMembers: [],
            },
        },
        M: {
            M: {
                addedMembers: [],
            },
            F: {
                addedMembers: [],
            },
        },
        R: {
            addedMembers: [],
        },
        totalMembers:[],
    };

    const reducer = (state, action) => {

        switch (action.type) {

            case "QUALIFIED_EVENTS": {
                const { role, gender, events } = action;
                state[role][gender].qualifiedEvents = [...events];
                break;
            }

            case "PLAYING": {
                const { role, gender, playing } = action;
                state[role][gender].playing = playing;
                break;
            }

            case "SELECT_EVENT": {
                const { role, gender, event, add } = action;
                const arr = state[role][gender].selectedEvents;
                if (add) arr.push(event);
                else arr.splice(arr.indexOf(event), 1);
                break;
            }

            case "ADD_MEMBER": {
                const { role, gender, member } = action;
                const arr = role === "R" ? state[role].addedMembers : state[role][gender].addedMembers;
                let isAdded = false;
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].regId === member.regId) {
                        isAdded = true;
                        break;
                    }
                }
                if (!isAdded) {
                    arr.push(member);
                    state.totalMembers.push(member.emailId);
                }
                break;
            }

            case "REMOVE_MEMBER": {
                const { role, gender, regId, emailId} = action;
                const arr = role === "R" ? state[role].addedMembers : state[role][gender].addedMembers;
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].regId === regId) {
                        arr.splice(i, 1);
                        break;
                    }
                }

                state.totalMembers.splice(state.totalMembers.indexOf(emailId), 1);

                break;
            }

            case "SELECT_MEMBER": {
                const { role, gender, event, regId } = action;

                let arr = [];
                if (role === "P") arr = state[role][gender].selectedMembers[event];
                else if (role === "C" || role === "M") arr = state[role][gender];
                else if (role === "R") arr = state[role];

                let isAdded = false;
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] === regId) {
                        isAdded = true;
                        break;
                    }
                }
                if (!isAdded) arr.push(regId);
                break;
            }

            case "UNSELECT_MEMBER": {
                const { role, gender, event, regId } = action;

                let arr = [];
                if (role === "P") arr = state[role][gender].selectedMembers[event];
                else if (role === "C" || role === "M") arr = state[role][gender];
                else if (role === "R") arr = state[role];

                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] === regId) {
                        arr.splice(i, 1);
                        break;
                    }
                }
                break;
            }
        }
        return { ...state };
    };

    const [members, dispatch] = useReducer(reducer, initialeState);
    const [national, setNational] = useState(selectedTournament);

    useEffect(() => {

        // dispatch({ type: "QUALIFIED_EVENTS", role: "P", gender: "M", events: ["TE", "RE", "DE", "QE"] });

        // dispatch({ type: "SELECT_EVENT", role: "P", gender: "M", event: "TE", add: true });
        // dispatch({ type: "SELECT_EVENT", role: "P", gender: "M", event: "DE", add: true });

        // dispatch({ type: "ADD_MEMBER", role: "P", gender: "M", member: { regId: "TNP001", name: "Yukesh" } });
        // dispatch({ type: "ADD_MEMBER", role: "P", gender: "M", member: { regId: "TNP002", name: "Elango" } });

        // dispatch({ type: "REMOVE_MEMBER", role: "P", gender: "M", regId: "TNP001" });

        // dispatch({ type: "SELECT_MEMBER", role: "P", gender: "M", event: "TE", regId: "TNP001" });
        // dispatch({ type: "SELECT_MEMBER", role: "P", gender: "M", event: "TE", regId: "TNP002" });
        // dispatch({ type: "UNSELECT_MEMBER", role: "P", gender: "M", event: "TE", regId: "TNP001" });

        // dispatch({ type: "SELECT_MEMBER", role: "C", gender: "M", regId: "TNO001" });
        // dispatch({ type: "SELECT_MEMBER", role: "M", gender: "F", regId: "TNO002" });
        // dispatch({ type: "UNSELECT_MEMBER", role: "M", gender: "F", regId: "TNO002" });

        // dispatch({ type: "SELECT_MEMBER", role: "R", regId: "TNR002" });
        // dispatch({ type: "UNSELECT_MEMBER", role: "R", regId: "TNR002" });

        const getQualifiedEvents = (events, gender) => {
            if (!events) return [];
            else return events.filter((event) => (event[gender === "M" ? "qualifiedMale" : "qualifiedFemale"]) ? true : false).map((v) => v.
                event
            );
        };

        dispatch({ type: "QUALIFIED_EVENTS", role: "P", gender: "M", events: getQualifiedEvents(selectedTournament[TOURNAMENT_FORM_KEY.EVENTS], "M") });
        dispatch({ type: "QUALIFIED_EVENTS", role: "P", gender: "F", events: getQualifiedEvents(selectedTournament[TOURNAMENT_FORM_KEY.EVENTS], "F") });

    }, [selectedTournament, dispatch]);

    console.log(members);

    const validation = (gender) => {

        if (members.P[gender].qualifiedEvents.length) {

            if (members.P[gender].playing === null) {
                return [false, "Confirm Participation"];
            }
            if (members.P[gender].playing === false) {
                return [true];
            }
            else if (members.P[gender].selectedEvents.length === 0) {
                return [false, "Choose atleast one event"];
            }
            else if (members.P[gender].selectedEvents.length > 0) {

                if (members.P[gender].selectedEvents.includes("TE")) {

                    if (members.P[gender].selectedMembers.TE.length < 9) {
                        return [false, "Select minimum of 9 players in a team event"];
                    }
                    else if (members.P[gender].selectedMembers.TE.length > 15) {
                        return [false, "Cannot select more than 15 players a in team event"];
                    }
                }

                if (members.P[gender].selectedEvents.includes("QE")) {

                    if (members.P[gender].selectedMembers.QE.length < 4) {
                        return [false, "Select minimum of 4 players in a Quad event"];
                    }
                    else if (members.P[gender].selectedMembers.QE.length > 6) {
                        return [false, "Cannot select more than 6 players a in Quad event"];
                    }
                }

                if (members.P[gender].selectedEvents.includes("RE")) {

                    if (members.P[gender].selectedMembers.RE.length < 3) {
                        return [false, "Select minimum of 3 players in a Regu event"];
                    }
                    else if (members.P[gender].selectedMembers.RE.length > 5) {
                        return [false, "Cannot select more than 5 players a in Regu event"];
                    }
                }

                if (members.P[gender].selectedEvents.includes("DE")) {

                    if (members.P[gender].selectedMembers.DE.length < 2) {
                        return [false, "Select minimum of 2 players in a Doubles event"];
                    }
                    else if (members.P[gender].selectedMembers.DE.length > 3) {
                        return [false, "Cannot select more than 3 players a in Doubles event"];
                    }
                }

                if (members.C[gender].addedMembers.length === 0) {
                    return [false, "Add atleast one coach"];
                }

                if (members.P[gender].selectedEvents.length > 0) {

                    if (members.P[gender].selectedEvents.includes("TE")) {

                        if (members.C[gender].addedMembers.length > 2) {
                            return [false, "Maximum two coaches can be added"];
                        }
                    }
                    else {
                        if (members.C[gender].addedMembers.length > 1) {
                            return [false, "Only one coach can be added"];
                        }
                    }
                }

                if (members.M[gender].addedMembers.length === 0) {
                    return [false, "Add atleast one manager"];
                }
                else if (members.M[gender].addedMembers.length > 1) {
                    return [false, "Only one manager can be added"];
                }

            }
        }
        return [true];
    };

    const onSubmit = useCallback(async () => {

        var [result, error] = validation("M");

        if (!result) {

            setSnackbar(snackbarProps.error(`Male players: ${error}`));
            return;
        }

        [result, error] = validation("F");

        if (!result) {

            setSnackbar(snackbarProps.error(`Female players: ${error}`));
            return;
        }

        var buttons = [];
        buttons.push(dialogButtonsProps.init("Confirm and submit", async () => {

            setDialog(dialogProps.close());

            var requestObj = {
                P: {
                    M: {
                        TE: [],
                        RE: [],
                        DE: [],
                        QE: [],
                    },
                    F: {
                        TE: [],
                        RE: [],
                        DE: [],
                        QE: [],
                    }
                },
                C: {
                    M: [],
                    F: [],
                },
                M: {
                    M: [],
                    F: [],
                },
                R: [],
            };

            requestObj.P.M = members.P.M.selectedMembers;
            requestObj.P.F = members.P.F.selectedMembers;

            requestObj.C.M = members.C.M.addedMembers.map((v) => v.regId);
            requestObj.C.F = members.C.F.addedMembers.map((v) => v.regId);

            requestObj.M.M = members.M.M.addedMembers.map((v) => v.regId);
            requestObj.M.F = members.M.F.addedMembers.map((v) => v.regId);

            requestObj.R = members.R.addedMembers.map((v) => v.regId);

            var fd = new FormData();
            fd.set("id", national.id);
            fd.set("registration", JSON.stringify(requestObj));

            try {

                snackbar.hide();
                setBackdrop(true);
                // Authenticate the google token

                const response = await API_STFI.TOURNAMENT_STATE_REGISTRATION(fd);

                if (response.status === 200) {
                    var buttons = [];
                    buttons.push(dialogButtonsProps.init("OK", () => {
                        setDialog(dialogProps.close());
                        navigate(-1);
                    }));

                    const name = national[TOURNAMENT_FORM_KEY.NAME];
                    const header = "National";

                    setDialog(dialogProps.info(header + " Tournament", name + " submitted successfully", buttons));
                    setBackdrop(false);
                }
                else {
                    setSnackbar(snackbarProps.error(ERROR_STR.STD));
                    setBackdrop(false);
                }

            } catch (error) {

                // Hide loader
                setBackdrop(false);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Server responded with an error:', error.response.status, error.response);

                    if (error.response.data && error.response.data.message) {
                        setSnackbar(snackbarProps.error(error.response.data.message));
                        setBackdrop(false);
                    }
                    else setSnackbar(snackbarProps.error(error.response.statusText));
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received from the server');
                    setSnackbar(snackbarProps.error(ERROR_STR.NO_RESPONSE));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error setting up the request:', error.message);
                    setSnackbar(snackbarProps.error(ERROR_STR.STD));
                }
            }

        }));
        buttons.push(dialogButtonsProps.init("Close", () => {
            setDialog(dialogProps.close());
        }));

        setDialog(dialogProps.info("Confirmation", `This is a one time submission process. Do you want to submit ?`, buttons));


    }, [navigate, snackbar]);


    return (
        <>
            <NationalsBasicDetails data={national} />
            {
                (members.P.M.qualifiedEvents.length) ?
                    <SelectNationalPlayers gender={"M"} players={members.P.M} coaches={members.C.M.addedMembers} managers={members.M.M.addedMembers} dispatch={dispatch} setBackdrop={setBackdrop} totalMembers = {members.totalMembers} />
                    :
                    <></>
            }
            {
                (members.P.F.qualifiedEvents.length) ?
                    <SelectNationalPlayers gender={"F"} players={members.P.F} coaches={members.C.F.addedMembers} managers={members.M.F.addedMembers} dispatch={dispatch} setBackdrop={setBackdrop} totalMembers = {members.totalMembers} />
                    :
                    <></>
            }
            <SelectNationalPlayers role={"R"} players={members.R} dispatch={dispatch} setBackdrop={setBackdrop} totalMembers = {members.totalMembers} />
            <Box sx={{ mt: 5, textAlign: "center" }} onClick={onSubmit} >
                <Button type='submit' variant='contained'>Proceed</Button>
            </Box>
            <Snackbar {...snackbar} />
            <Backdrop open={backdrop} />
            <Dialog {...dialog} />
        </>
    )
}

export default SelectNationalsMembers