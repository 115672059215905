import axios from "axios";

export const API_STFI = {

    LOGIN: (token) => {
        return axios.post("stfi/login", null, {headers: {"authentication" : token}});
    },

    NEWS_FEED: () => {
        return axios.get("/stfi/newsAndUpdate/getNews");
    },

    NEWS_FEED_DELETE: (id) => {
        return axios.delete("/stfi/admin/newsAndUpdate/deleteNews/"+id);
    },
    
    NEWS_FEED_ADD: (formData) => {
        return axios.post("/stfi/admin/newsAndUpdate/insertNews", formData);
    },

    GALLERY: () => {
        return axios.get("/stfi/homePageImage/getHomePageImages");
    },

    GALLERY_DELETE: (filename) => {
        return axios.delete("/stfi/admin/homePageImage/deleteImage/"+filename);
    },
    
    GALLERY_ADD: (formData) => {
        return axios.post("/stfi/admin/homePageImage/insertImage", formData);
    },

    PROFILE: () => {
        return axios.get("/stfi/profile/getProfileDetails");
    },

    USERS: () => {
        return axios.get("/stfi/admin/getUsers");
    },
    
    USERS_DELETE: (email) => {
        return axios.delete("/stfi/admin/deleteUser/"+email);
    },

    USERS_ADD: (formData) => {
        return axios.post("/stfi/admin/createUser", formData);
    },

    USERS_UPDATE: (formData) => {
        return axios.post("/stfi/admin/updateUser", formData);
    },

    USERS_GET: (email) => {
        return axios.get("/stfi/admin/getUser/"+email);
    },

    STATES: () => {
        return axios.get("/stfi/admin/getAllStateCode");
    },

    STATES_DELETE: (state) => {
        return axios.delete("/stfi/admin/deleteStateCode/"+state);
    },

    STATES_ADD: (data) => {
        return axios.post("/stfi/admin/insertStateCode", data);
    },

    REGISTRATION_GET_ADMIN: (stateCode, gender, status, role) => {
        if(status !== "A"){
            return axios.post(`/stfi/admin/registration/getRegistration?status=${(status)?status.toUpperCase():status}&stateCode=${(stateCode)?stateCode.toUpperCase():stateCode}`);
        }
        else return axios.post(`/stfi/admin/registration/getRegistration?gender=${(gender)?gender.toUpperCase():gender}&status=${(status)?status.toUpperCase():status}&role=${(role)?role.toUpperCase():role}&stateCode=${(stateCode)?stateCode.toUpperCase():stateCode}`);
    },

    REGISTRATION_GET_STATEADMIN: (gender, status, role) => {

        if(status !== "A"){
            return axios.post(`/stfi/stateAdmin/registration/getRegistration?status=${(status)?status.toUpperCase():status}`);
        }
        return axios.post(`/stfi/stateAdmin/registration/getRegistration?gender=${(gender)?gender.toUpperCase():gender}&status=${(status)?status.toUpperCase():status}&role=${(role)?role.toUpperCase():role}`);
    },

    REGISTRATION_PREVIEW: (url) => {
        return axios.get(url);
    },

    REGISTRATION_UPDATE: (formData) => {
        return axios.put("/stfi/stateAdmin/registration/updateRegistration", formData,{
            headers:{"Content-Type": "multipart/form-data"}
        });
    },

    REGISTRATION_ADD_ROLES: (formData) => {
        return axios.post("/stfi/stateAdmin/registration/addRegistration", formData,{
            headers:{"Content-Type": "multipart/form-data"}
        });
    },

    APPROVE_REJECT_REGISTRATION: (obj) => {
        return axios.put("/stfi/admin/registration/updateRegistration", obj);
    },

    REGISTRATION_FORM_1: (obj) => {
        return axios.post("/stfi/stateAdmin/registration/getRegisteredRoles", obj);
    },

    CREATE_REGISTRATION: (formData) => {
        return axios.post("/stfi/stateAdmin/registration/createRegistration", formData,{
            headers:{"Content-Type": "multipart/form-data"}
        });   
    },

    GET_REGISTRATION_COUNT_STATE_ADMIN: () => {
        return axios.get("/stfi/stateAdmin/registration/getRegistrationCount");
    },

    GET_REGISTRATION_COUNT_ADMIN: () => {
        return axios.get("/stfi/admin/registration/getRegistrationCount");
    },

    GET_REGISTRATION_PENDING: () => {
        return axios.get("/stfi/admin/registration/getPendingRegistration");
    },

    _GET_REGISTRATION_ID: (status, stateCode, gender, role, name) => {
        return axios.get(`/stfi/admin/tournament/getRegistrationDetail?stateCode=${stateCode}&gender=${gender}&role=${role}&name=${name}`);
    },

    GET_REGISTRATION_ID: (id) => {
        return axios.get(`/stfi/admin/tournament/getRegistrationDetail?registrationId=${id}`);
    },

    GET_SA_REGISTRATION_ID: (id) => {
        return axios.get(`/stfi/stateAdmin/tournamentRegistration/getRegistrationById/${id}`);
    },

    TOURNAMENT_CREATE: () => {
        return axios.get("/stfi/admin/tournament/create");
    },

    TOURNAMENT_VIEW: () => {
        return axios.get("/stfi/admin/tournament/getAllTournaments?status=inprogress");
    },

    TOURNAMENT_STATE_VIEW: () => {
        return axios.get(`/stfi/stateAdmin/tournamentRegistration/getAllTournaments?status=inprogress`);
    },

    TOURNAMENT_DELETE: (id) => {
        return axios.delete(`/stfi/admin/tournament/deleteTournament/${id}`);
    },

    CREATE_TOURNAMENT: (formData) => {
        return axios.post("/stfi/admin/tournament/createTournament", formData,{
            headers:{"Content-Type": "multipart/form-data"}
        }); 
    },

    UPDATE_TOURNAMENT: (id, formData) => {
        return axios.put(`/stfi/admin/tournament/updateTournament/${id}`, formData,{
            headers:{"Content-Type": "multipart/form-data"}
        }); 
    },

    TOURNAMENT_STATE_REGISTRATION: (formData) => {
        return axios.post(`/stfi/stateAdmin/tournamentRegistration`, formData,{
            headers:{"Content-Type": "multipart/form-data"}
        }); 
    },

    TOURNAMENT_STATE_GET_NATIONAL_DETAILS: (id, stateCode) => {
        if(stateCode) return axios.get(`/stfi/admin/tournamentRegistration/getTournamentDetails/${id}/${stateCode}`); 
        else return axios.get(`/stfi/stateAdmin/tournamentRegistration/getTournamentDetails/`+id); 
    },
};